html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.MuiNativeSelect-root::before {
  display: none !important;
}

button.PrivateDatePickerToolbar-penIcon {
  display: none !important;
}

@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }

  @page {
    size: auto; /* auto is the initial value */
    margin: 0; /* this affects the margin in the printer settings */
  }
}
