@font-face {
  font-family: "AvenirNext";
  src: url("/src/assets/font/AvenirNext-Heavy.woff") format("woff"),
    url("/src/assets/font/AvenirNext-Heavy.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "AvenirNext";
  src: url("/src/assets/font/AvenirNext-Bold.woff") format("woff"),
    url("/src/assets/font/AvenirNext-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "AvenirNext";
  src: url("/src/assets/font/AvenirNext-Medium.woff") format("woff"),
    url("/src/assets/font/AvenirNext-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "AvenirNext";
  src: url("/src/assets/font/AvenirNext-Regular.woff") format("woff"),
    url("/src/assets/font/AvenirNext-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "AvenirNext";
  src: url("/src/assets/font/AvenirNext-Italic.woff") format("woff"),
    url("/src/assets/font/AvenirNext-Italic.woff") format("woff");
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "MinervaModern";
  src: url("/src/assets/font/MINERVAM.OTF") format("opentype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "SofiaPro";
  src: url("/src/assets/font/sofiapro-light.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "MuseoSans";
  src: url("/src/assets/font/MuseoSans_700.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "MuseoSans";
  src: url("/src/assets/font/MuseoSans_500.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Berling LT Std";
  src: url("/src/assets/font/BerlingLTStd-Roman.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Futura Std";
  src: url("/src/assets/font/FuturaStdMedium.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "CABOTO";
  src: url("/src/assets/font/CABOTO-Bold.woff") format("woff"),
    url("/src/assets/font/CABOTO-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "CABOTO";
  src: url("/src/assets/font/CABOTO-Regular.woff") format("woff"),
    url("/src/assets/font/CABOTO-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  src: url("/src/assets/font/Poppins-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Nunito";
  src: url("/src/assets/font/nunito-regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "TenorSans";
  src: url("/src/assets/font/TenorSans-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Termina";
  src: url("/src/assets/font/TerminaTest-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Barlow";
  src: url("/src/assets/font/barlow-regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "DMSans";
  src: url("/src/assets/font/DMSans-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Touvlo";
  src: url("/src/assets/font/Touvlo-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "SegoeUI";
  src: url("/src/assets/font/SegoeUI.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Hurme";
  src: url("/src/assets/font/HurmeRegular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}